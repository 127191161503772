import request from "@/utils/request";

export function teachers() {
  return request({
    method: "POST",
    url: "employee/teachers"
  });
}

export function lessons(data) {
  return request({
    method: "POST",
    url: "lesson/in_grade_class",
    data: data
  });
}

export function silabus(data) {
  return request({
    method: "POST",
    url: "silabus/list",
    data: data
  });
}

export function rpp(data) {
  return request({
    method: "POST",
    url: "rpp/list",
    data: data
  });
}

export function classLesson(data) {
  return request({
    method: "POST",
    url: "class/in_lesson_teacher",
    data: data
  });
}

export function saveSilabus(data) {
  return request({
    method: "POST",
    url: "silabus/set",
    data: data
  });
}

export function saveRPP(data) {
  return request({
    method: "POST",
    url: "rpp/set",
    data: data
  });
}

export function getSilabus(data) {
  return request({
    method: "POST",
    url: "silabus/get",
    data: data
  });
}

export function getRPP(data) {
  return request({
    method: "POST",
    url: "rpp/get",
    data: data
  });
}

export function getDocumentItem(data) {
  return request({
    method: "POST",
    url: "document/get_documents_by_teacher",
    data: data
  });
}

export function getDocument(data) {
  return request({
    method: "POST",
    url: "document/list",
    data: data
  });
}

export function saveDocument(data) {
  return request({
    method: "POST",
    url: "document/insert",
    data: data
  });
}
