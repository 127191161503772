<template>
  <v-card class="pa-4" flat>
    <v-row no-gutters class="mb-3">
      <v-col cols="12" class="d-flex">
        <FilterSilabus @handler="handleFilter" />
        <v-spacer />
        <v-btn
          class="gradient-primary"
          dark
          depressed
          @click="dialogAdd = true"
        >
          <span v-if="$vuetify.breakpoint.xlOnly" class="mr-2">
            {{ $t("routes.upload_documents") }}
          </span>
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-3" />
    <TableSilabus
      :table="table"
      :dataTable="dataTable"
      :loading="loadingTable"
      :documentType="documentType"
      @getData="getData"
      @paginateChange="paginateChange"
    />
    <DialogSilabus
      :dialog="dialogAdd"
      :name="$t('app.document')"
      @close="closeDialog"
    />
  </v-card>
</template>

<script>
import { getDocument } from "@/api/admin/academic/silabus";
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.upload_documents"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    FilterSilabus: () => import("../../components/silabus/filter"),
    TableSilabus: () => import("../../components/silabus/table"),
    DialogSilabus: () => import("../../components/silabus/dialog")
  },
  data() {
    return {
      employeeID: this.$store.getters.g_user.employee_id,
      dataTable: [],
      loadingTable: false,
      dialogAdd: false,
      table: {
        length: 0,
        total: 0,
        page: 1,
        limit: 10
      },
      idSchoolYear: 0,
      idGrade: 0,
      idClass: 0,
      idSubjects: 0,
      idTeacher: 0,
      search: "",
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      documentType: "OTHER"
    };
  },
  methods: {
    closeDialog() {
      this.dialogAdd = false;
      this.getData();
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getData();
    },
    async getData() {
      this.loadingTable = true;
      const body = {
        classroom: this.idClass,
        grade: this.idGrade,
        lesson: this.idSubjects,
        limit: this.table.limit,
        page: this.table.page,
        school_year: this.idSchoolYear,
        search: this.search,
        teacher: this.isTeacher ? this.employeeID : this.idTeacher,
        document_type: this.documentType
      };
      try {
        const response = await getDocument(body);
        if (response.data.code) {
          const result = response.data;
          this.dataTable = result.data.data.map(item => ({
            ...item,
            viewMore: false
          }));
          this.table.length = result.data.last_page;
          this.table.total = result.data.total;
        }
      } catch (error) {
        console.error("getData()\n", error);
        this.loadingTable = false;
      }
      this.loadingTable = false;
    },
    handleFilter(param) {
      switch (param.type) {
        case "school_year":
          this.idSchoolYear = param.selected;
          break;
        case "grade":
          this.idGrade = param.selected;
          break;
        case "class":
          this.idClass = param.selected;
          break;
        case "subjects":
          this.idSubjects = param.selected;
          break;
        case "teacher":
          this.idTeacher = param.selected;
          break;
        case "type":
          this.documentType = param.selected;
          break;
      }
      this.getData();
    }
  }
};
</script>
